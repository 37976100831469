<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <bread-crumb></bread-crumb>
        </div>
        <el-card class="el-main goodsOrder">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="false" id="leSearch">
                <le-company-under-select label="商户" placeholder="请选择（可输入搜索）" v-model="pageParam.params.companyId"></le-company-under-select>
                <le-date-range label="日期" :clearable='false' :pickerOptions="pickerOptions" :minDate.sync="pageParam.params.startTime" :maxDate.sync="pageParam.params.endTime" valueFormat="yyyy-MM-dd HH:mm:ss" />
                <le-input label="采购ID" v-model="pageParam.params.purchaseId" />
                <el-popover
                    placement="bottom"
                    title="说明"
                    width="400"
                    trigger="hover">
                    <div class="a-flex-cfsfs" style="line-height: 24px">
                        <span>1. 欠款：当客户购买充电桩设备时会按照合同进行设备款或服务费用的欠款；</span>
                        <span>2. 还款：客户的还款行为对应于之前欠款产生的债务；</span>
                        <span>3. 还款退还：在用户通过充电桩设备进行消费并产生还款之后，如果这笔订单之前记录为一笔还款那么，还款数据会进行订正为一笔负数的还款；</span>
                        <span>4. 设备会对所有采购的欠款做还款操作；</span>
                        <span>5. 当已还款为负数时那么说明还款退款的金额大于今日还款金额（即退款金额多）;</span>
                    </div>
                    <i slot="reference" class="el-icon-info a-fs-20" style="line-height: 32px;padding: 12px"></i>
                </el-popover>
            </le-search-form>
            <le-pagview ref="repaymentDevDetailsPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.goodsOrderList">
                <el-table ref="goodsOrderList" @filter-change='filterFun' :data="tableData" :highlight-current-row="true" v-sticky="{ top: 0, parent:'.el-card__body' }" style="width: 100%">
                    <el-table-column label="采购ID" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.purchaseId || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="类型"
                        column-key="recordType"
                        :filter-multiple='false'
                        :filters="recordTypeDic" 
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.recordType | initDic(recordTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="类型明细" 
                        column-key="financeBusinessType"
                        :filter-multiple='false'
                        :filters="financeBusinessTypeDic" 
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.businessType | initDic(financeBusinessTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="金额(元)" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ utils.numFormat(row.recordAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="关联订单" min-width="120">
                        <template slot-scope="{ row }">
                            <span class="a-c-blue font-point" @click="toOrderDetails(row)">{{ row.orderSn || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="订单类型" 
                        column-key="orderType"
                        :filter-multiple='false'
                        :filters="orderTypeDic" 
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.orderType | initDic(orderTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="当日还款进度(元)" min-width="220">
                        <template slot-scope="{ row }">
                            <div class="a-flex-rfsc a-flex-wrap">
                                <span>{{ utils.numFormat(row.todayRepaymentAmount) }}/{{ utils.numFormat(row.todayAmount) }}</span>
                                <span v-if="row.recordTime">（{{ row.recordTime.split(' ')[0] }}）</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="日期" min-width="160">
                        <template slot-scope="{ row }">
                            <span>{{ row.recordTime || '-' }}</span>
                        </template>
                    </el-table-column> 
                </el-table>
                <div style="height: 15px"></div>
                <template slot="footerContent" slot-scope="scope" >
                    <div class="a-flex-rfsc a-flex-wrap">
                        <div class="a-flex-rfsc" style="margin-right: 20px">
                            <span class="a-fs-12 a-c-normal">搜索时间内已还金额：</span>
                            <span class="a-fs-16 a-c-master a-fw-b">￥{{utils.numFormat(scope.val.recordAmount)}}</span>
                        </div>
                        <div class="a-flex-rfsc" style="margin-right: 20px">
                            <span class="a-fs-12 a-c-normal">搜索时间内待还金额：</span>
                            <span class="a-fs-16 a-c-master a-fw-b">￥{{utils.numFormat(scope.val.needAmount)}}</span>
                        </div>
                        <div class="a-flex-rfsc">
                            <span class="a-fs-12 a-c-normal">搜索时间内欠款金额：</span>
                            <span class="a-fs-16 a-c-master a-fw-b">￥{{utils.numFormat(scope.val.totalAmount)}}</span>
                        </div>
                    </div>
                </template>
            </le-pagview>
        </el-card>
    </div>
</template>
<script>
import utils from '../../utils/util'
export default {
    data() {
        return {
            utils: utils,
            tableData: [],
            pageParam: null,
            choiceDate: '',
            pickerOptions: {
                onPick: ({ maxDate, minDate }) => {
                    this.choiceDate = minDate.getTime()
                    if (maxDate) this.choiceDate = ''
                },
                disabledDate: (time) => {
                    if (this.choiceDate) {
                        const one = 92 * 24 * 3600 * 1000
                        const minTime = this.choiceDate - one
                        const maxTime = this.choiceDate + one
                        return time.getTime() < minTime || time.getTime() > maxTime
                    }
                },
            },
            purchaseId: '',
            companyId: '',
            recordTypeDic: [],
            orderTypeDic: [],
            financeBusinessTypeDic: [],
        }
    },
    created() {
        this.$getDic('financeOrderType').then(res=>{ this.orderTypeDic = res; })
        this.$getDic('financeRecordType').then(res=>{ this.recordTypeDic = res; })
        this.$getDic('financeBusinessType').then(res=>{ this.financeBusinessTypeDic = res; })
        this.purchaseId = this.$route.query.purchaseId
        this.companyId = this.$route.query.companyId?Number(this.$route.query.companyId):''
        this.pageParam = {
            url: this.$Config.apiUrl.getPurchaseRepaymentRecord,
            method: "post",
            params: {
                purchaseId: this.purchaseId,
                startTime: this.$getDay.getTodayBeforeDays(0) + ' 00:00:00',
                endTime: this.$getDay.getTodayBeforeDays(0) + ' 23:59:59',
                companyId: this.companyId,
                recordOrderType: '',
                recordType: '',
                businessType: ''
            },
            freshCtrl: 1,
        };
    },
    activated () {
        this.pageParam.freshCtrl++;
    },
    methods: {
        setTableData(data) {
            this.tableData = data
        },
        handlerRest() {
            this.pageParam.params = {
                purchaseId: this.purchaseId,
                startTime: this.$getDay.getTodayBeforeDays(0) + ' 00:00:00',
                endTime: this.$getDay.getTodayBeforeDays(0) + ' 23:59:59',
                companyId: "",
                recordOrderType: '',
                recordType: '',
                businessType: '',
            };
            this.handlerSearch()
        },
        handlerSearch() {
            this.$refs['repaymentDevDetailsPage'].pageNum = 1
            this.pageParam.freshCtrl++;
        },
        //条件筛选
        filterFun(value){
            for(var key in value){
                if(key=='recordType'){
                    if(value[key].length==0){
                        this.pageParam.params.recordType = ''
                    }else{
                        this.pageParam.params.recordType = value[key][0]
                    }
                }
                if(key=='orderType'){
                    if(value[key].length==0){
                        this.pageParam.params.recordOrderType = ''
                    }else{
                        this.pageParam.params.recordOrderType = value[key][0]
                    }
                }
                if(key=='financeBusinessType'){
                    if(value[key].length==0){
                        this.pageParam.params.businessType = ''
                    }else{
                        this.pageParam.params.businessType = value[key][0]
                    }
                }
            }
            this.handlerSearch()
        },
        toOrderDetails (datas) {
            switch (datas.orderType) {
                case 1:
                    // 充电桩订单
                    if (!datas.orderSn || datas.orderSn=='null' || !this.$_has(4)) return
                    this.$router.push({
                        path: '/order/order-detail',
                        query: {
                            orderId: datas.orderSn,
                        }
                    })
                    break;
                case 2:
                    // 套餐订单
                    if (!datas.orderSn || datas.orderSn=='null' || !this.$_has(72)) return
                    this.$router.push({
                        path:'/order/package-order-info',
                        query: {
                            id: datas?datas.orderSn:''
                        }
                    })
                    break;
                case 3:
                    // 水机订单
                    break;
                case 4:
                    // 充电柜订单
                    if (!datas.orderSn || datas.orderSn=='null' || !this.$_has(101)) return
                    this.$router.push({
                        path:'/order/chargeCabinet-order-info',
                        query: {
                            orderId: datas.orderSn,
                            orderSn: datas.orderSn
                        }
                    })
                    break;
                default:
                    break;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.goodsOrder {
    padding: 8px 4px;
}
.s-btn-add{
    width: 105px;
}
.gocompany {
    color: #007AFF;
    cursor: pointer;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
</style>